import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import styled from 'styled-components';

import {
  categoryOptions,
  editInitialValues,
  editValidationSchema,
  formatPrice,
  getDiscountedPrice,
  getEntryPrice,
  mapEntryToSubmittedForm,
  projectCategoryCheckboxes,
} from '../helpers';
import * as S from '../styles';
import { apiClient } from 'helpers/APIClient';
import { apiUrls, mediaQueries, routes } from 'variables';
import { Input } from 'components/form/Input';
import { TextArea } from 'components/form/TextArea';
import { ProjectStatus } from 'typings/registerForm';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { ImageUpload } from 'components/form/ImageUpload';

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -16px;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const CategoryTitle = styled.h3`
  margin: 16 0 32px;
`;

export const EditForm: React.FC = () => {
  const [isFetchingEntry, setIsFetchingEntry] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(editInitialValues);
  const [fee, setFee] = React.useState({ final: '0.00', regular: '0.00', discount: '0.00' });

  const history = useHistory();
  const { search } = useLocation();

  const projectId = search.slice(4);

  React.useEffect(() => {
    setIsFetchingEntry(true);

    apiClient
      .get(`${apiUrls.getEntryDetails}${projectId}`)
      .then(({ data }) => {
        if (data.status !== ProjectStatus.Submitted) {
          history.push(routes.entriesList);
        }

        mapEntryToSubmittedForm(data, setInitialValues);
        const categoryNumbers = data.awardCategory.length;
        const price = categoryNumbers * getEntryPrice(data.participantCategory);
        const discountedPrice = getDiscountedPrice(price, categoryNumbers);
        setFee({
          final: formatPrice(discountedPrice),
          regular: formatPrice(price),
          discount: formatPrice(price - discountedPrice),
        });
        setIsFetchingEntry(false);
      })
      .catch(() => history.push(routes.entriesList));
  }, []);

  return (
    <S.Wrapper>
      <S.FormHeader>
        <S.Title>Preparation Entry to the Awards</S.Title>
      </S.FormHeader>

      {isFetchingEntry ? (
        <S.Loader>
          <i className="fa fa-circle-o-notch fa-spin" />
        </S.Loader>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={editValidationSchema}
          validateOnMount
          onSubmit={values => {
            setIsFetching(true);

            const images = Array.from(Array(5).keys())
              .map(key => key + 1)
              .reduce(
                (acc: unknown[], curr) =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  values[`image${curr}`] === ''
                    ? acc
                    : [
                        ...acc,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        { image: values[`image${curr}`], credits: values[`credits${curr}`] || '' },
                      ],
                [],
              );

            apiClient
              .put(`${apiUrls.updateEntry}${projectId}/`, { ...values, images })
              .then(() => history.push(routes.entriesList))
              .then(() => toast('Successfully updated the entry', toastSettings))
              .catch(() => {
                toast('Updating the entry failed', toastSettings);
                setIsFetching(false);
              });
          }}
        >
          {({ errors, values, touched, handleChange, setFieldValue, setFieldError }) => (
            <S.StyledForm>
              <InputsWrapper>
                <CategoryTitle>Contact details</CategoryTitle>
                <Input
                  label="Full name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  error={errors.fullName}
                  touched={touched.fullName}
                />
                <Input
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                  touched={touched.email}
                />

                <CategoryTitle>Team information</CategoryTitle>
                <Input
                  label="Company name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  error={errors.companyName}
                  touched={touched.companyName}
                />
                <Input
                  label="Website (optional)"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  error={errors.website}
                  touched={touched.website}
                />
                <Input
                  label="Country"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  error={errors.country}
                  touched={touched.country}
                />
                <TextArea
                  label="Design team"
                  name="designTeam"
                  value={values.designTeam}
                  onChange={handleChange}
                  error={errors.designTeam}
                  touched={touched.designTeam}
                  maxLength={200}
                />

                <CategoryTitle>Project details</CategoryTitle>
                <Input
                  label="Project name"
                  name="projectName"
                  value={values.projectName}
                  onChange={handleChange}
                  error={errors.projectName}
                  touched={touched.projectName}
                />
                <Input
                  label="Client (optional)"
                  name="client"
                  value={values.client}
                  onChange={handleChange}
                  error={errors.client}
                  touched={touched.client}
                />
                <Input
                  label="Location (optional)"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  error={errors.location}
                  touched={touched.location}
                />
                <TextArea
                  label="Project description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  error={errors.description}
                  touched={touched.description}
                  maxLength={2000}
                />

                <ImageUpload
                  setFieldValue={setFieldValue}
                  name="image1"
                  label="Entry featured image (will present in main promotional elements)"
                  error={errors.image1}
                  setFieldError={setFieldError}
                  value={values.image1}
                />
                <Input
                  label="Credits"
                  name="credits1"
                  value={values.credits1}
                  onChange={handleChange}
                  error={errors.credits1}
                  touched={touched.credits1}
                />

                <ImageUpload
                  setFieldValue={setFieldValue}
                  name="image2"
                  label="Entry image 2"
                  setFieldError={setFieldError}
                  value={values.image2}
                />
                <Input
                  label="Credits"
                  name="credits2"
                  value={values.credits2}
                  onChange={handleChange}
                  error={errors.credits2}
                  touched={touched.credits2}
                />
                <ImageUpload
                  setFieldValue={setFieldValue}
                  name="image3"
                  label="Entry image 3"
                  setFieldError={setFieldError}
                  value={values.image3}
                />
                <Input
                  label="Credits"
                  name="credits3"
                  value={values.credits3}
                  onChange={handleChange}
                  error={errors.credits3}
                  touched={touched.credits3}
                />
                <ImageUpload
                  setFieldValue={setFieldValue}
                  name="image4"
                  label="Entry image 4"
                  setFieldError={setFieldError}
                  value={values.image4}
                />
                <Input
                  label="Credits"
                  name="credits4"
                  value={values.credits4}
                  onChange={handleChange}
                  error={errors.credits4}
                  touched={touched.credits4}
                />
                <ImageUpload
                  setFieldValue={setFieldValue}
                  name="image5"
                  label="Entry image 5"
                  setFieldError={setFieldError}
                  value={values.image5}
                />
                <Input
                  label="Credits"
                  name="credits5"
                  value={values.credits5}
                  onChange={handleChange}
                  error={errors.credits5}
                  touched={touched.credits5}
                />
              </InputsWrapper>

              <S.FeeAndNextSection>
                <S.Fee>
                  Registration fee:{' '}
                  {values.status === ProjectStatus.Submitted ? (
                    <span>
                      PAID (<u>{`$${fee.final}`}</u>)
                    </span>
                  ) : (
                    <u>{`$${fee.final}`}</u>
                  )}
                </S.Fee>
                <S.FeeParagraph>
                  Your discount: <u>-${fee.discount}</u>
                </S.FeeParagraph>
                <S.FeeParagraph>
                  Regular fee: <u>${fee.regular}</u>
                </S.FeeParagraph>
                <S.Detail>
                  <u>Participant category:</u>{' '}
                  {categoryOptions.find(({ value }) => values.participantCategory === value)?.label}
                </S.Detail>
                <S.Detail>
                  <u>Awards category:</u>{' '}
                  {values.awardCategory
                    .map(
                      category =>
                        projectCategoryCheckboxes.find(({ value }) => value === category)?.label,
                    )
                    .join(', ')}
                </S.Detail>
                <S.Detail>* if you want to change category, please contact us</S.Detail>
                <br />

                <S.NextButton type="submit" disabled={isFetching}>
                  Save entry
                  {isFetching && (
                    <span>
                      <i className="fa fa-circle-o-notch fa-spin" />
                    </span>
                  )}
                </S.NextButton>
              </S.FeeAndNextSection>
            </S.StyledForm>
          )}
        </Formik>
      )}
    </S.Wrapper>
  );
};
