import styled from 'styled-components';
import { Form } from 'formik';

import { PrimaryButton } from 'components/common/Buttons';
import { colors, mediaQueries } from 'variables';

export const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -12px;

  ${mediaQueries.lg} {
    flex-direction: column-reverse;
    margin-top: 100px;
  }
`;

export const Title = styled.h2`
  margin: 59px 0 64px;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 64px;
  }
`;

export const SubTitle = styled.p`
  margin: 16px 0 64px;
  text-align: center;

  ${mediaQueries.lg} {
    margin-bottom: 96px;
  }
`;

export const StepBlocks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 96px;

  ${mediaQueries.lg} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column-reverse;

  ${mediaQueries.lg} {
    flex-direction: row;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 56px;
`;

export const Detail = styled.p`
  white-space: pre-line;
  word-wrap: break-word;
`;

export const FeeAndNextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: flex-start;

  ${mediaQueries.lg} {
    width: 390px;
    position: sticky;
    top: 110px;
  }
`;

export const Fee = styled.h2`
  margin: 0 0 16px;
`;

export const FeeParagraph = styled.p`
  margin: 0 0 12px;

  :nth-of-type(2) {
    margin-bottom: 42px;
  }
`;

export const NextButton = styled(PrimaryButton)`
  margin-bottom: 64px;

  ${mediaQueries.lg} {
    width: 256px;
  }
`;

export const PayPalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  align-self: center;

  ${mediaQueries.lg} {
    align-self: flex-start;
    margin: initial;
    width: 271px;
  }
`;

export const PayPalMessage = styled.p`
  font-size: 12px;
  line-height: 15px;
`;

export const ErrorMessage = styled(PayPalMessage)`
  color: ${colors.error};

  ${mediaQueries.lg} {
    margin: initial;
  }
`;

export const PayPalDisclaimer = styled.p``;

export const CardButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #2c2e2e;
  border: none;
  color: ${colors.primary};
  margin-bottom: 48px;
  cursor: pointer;

  ${mediaQueries.lg} {
    width: 271px;
  }
`;

export const Link = styled.a`
  color: ${colors.primary};
`;
