import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { apiUrls, mediaQueries } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { Input } from 'components/form/Input';
import {
  validationSchema,
  passwordInitialValues,
  passwordValidationSchema,
  PasswordValues,
} from './helpers';
import { toastSettings } from 'helpers/toastSettings';
import { TextArea } from 'components/form/TextArea';
import { AuthContext, setUserData, User } from 'contexts/AuthContext';
import { apiClient } from 'helpers/APIClient';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const Title = styled.h2`
  margin: 42px 0;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 640px;
`;

const StyledButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 270px;
`;

const SubTitle = styled.h3`
  margin: 54px 0 32px;
`;

const ExpandableSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  u {
    cursor: pointer;
  }
`;

export const MyProfile: React.FC = () => {
  const {
    state: { user },
    dispatch,
  } = React.useContext(AuthContext);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetchingPassword, setIsFetchingPassword] = React.useState(false);
  const [isPasswordSectionExpanded, setIsPasswordSectionExpanded] = React.useState(false);

  const initialValues = {
    fullName: user.fullName || '',
    street: user.street || '',
    zipCode: user.zipCode || '',
    city: user.city || '',
    country: user.country || '',
    bio: user.bio || '',
  };

  const onSubmit = (values: Omit<User, 'id'>) => {
    setIsFetching(true);

    apiClient
      .patch(apiUrls.updateProfile, values)
      .then(({ data }) => dispatch(setUserData(data)))
      .then(() => toast('Profile updated successfully', toastSettings))
      .catch(() => toast('Profile update failed', toastSettings))
      .finally(() => setIsFetching(false));
  };

  const onSubmitPassword = (values: PasswordValues) => {
    setIsFetchingPassword(true);

    apiClient
      .put(apiUrls.changePassword, values)
      .then(() => toast('Password changed successfully', toastSettings))
      .catch(err => toast(err.response.data.errors[0].message, toastSettings))
      .finally(() => setIsFetchingPassword(false));
  };

  return (
    <Wrapper>
      <Title>My profile</Title>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange }) => (
          <StyledForm>
            <Input
              label="Name"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              error={errors.fullName}
              touched={touched.fullName}
            />
            <Input label="Street" name="street" value={values.street} onChange={handleChange} />
            <Input
              label="Zip code"
              name="zipCode"
              value={values.zipCode}
              onChange={handleChange}
              max={5}
            />
            <Input label="City" name="city" value={values.city} onChange={handleChange} />
            <Input label="Country" name="country" value={values.country} onChange={handleChange} />
            <TextArea
              label="Bio"
              name="bio"
              value={values.bio}
              onChange={handleChange}
              maxLength={500}
            />

            <StyledButton type="submit" disabled={isFetching}>
              Update info
              {isFetching && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin" />
                </span>
              )}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>

      <Formik
        initialValues={passwordInitialValues}
        validationSchema={passwordValidationSchema}
        onSubmit={onSubmitPassword}
      >
        {({ values, errors, touched, handleChange }) => (
          <StyledForm>
            <ExpandableSectionHeader>
              <SubTitle>Password</SubTitle>{' '}
              <u onClick={() => setIsPasswordSectionExpanded(!isPasswordSectionExpanded)}>
                {isPasswordSectionExpanded ? 'Hide' : 'Show'}
              </u>
            </ExpandableSectionHeader>

            {isPasswordSectionExpanded && (
              <>
                <Input
                  label="Current password"
                  name="currentPassword"
                  type="password"
                  value={values.currentPassword}
                  onChange={handleChange}
                  error={errors.currentPassword}
                  touched={touched.currentPassword}
                />
                <Input
                  label="New password"
                  name="newPassword"
                  type="password"
                  value={values.newPassword}
                  onChange={handleChange}
                  error={errors.newPassword}
                  touched={touched.newPassword}
                />
                <StyledButton type="submit" disabled={isFetchingPassword}>
                  Update password
                  {isFetchingPassword && (
                    <span>
                      <i className="fa fa-circle-o-notch fa-spin" />
                    </span>
                  )}
                </StyledButton>
              </>
            )}
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};
