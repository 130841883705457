import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import { Header } from "components/common/Header";
import { Footer } from "components/common/Footer";
import { AwardsRegister } from "pages/awardsRegister/components/AwardsRegister";
import { SuccessPage } from "pages/SuccessPage";
import { colors, routes } from "variables";

import AssociateMonoLightWoff from "./assets/fonts/AssociateMono-Light.woff";
import AssociateMonoLightWoff2 from "./assets/fonts/AssociateMono-Light.woff2";
import AssociateMonoMediumWoff from "./assets/fonts/AssociateMono-Medium.woff";
import AssociateMonoMediumWoff2 from "./assets/fonts/AssociateMono-Medium.woff2";
import { Register } from "./pages/Auth/Register";
import { Login } from "./pages/Auth/Login";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";
import { ForgotPasswordSuccess } from "./pages/Auth/ForgotPasswordSuccess";
import { ChangePassword } from "./pages/Auth/ChangePassword";
import { ChangePasswordSuccess } from "./pages/Auth/ChangePasswordSuccess";
import { RegistrationEnded } from "./pages/RegistrationEnded";
import { Homepage } from "./pages/Homepage";
import { AuthContextProvider } from "contexts/AuthContext";
import LoggedIn from "components/common/LoggedIn";
import { MyProfile } from "pages/profile/MyProfile";
import { EntriesList } from "pages/entriesList/EntriesList";
import { EditForm } from "pages/awardsRegister/components/EditForm";
import {
  closeRegisterModal,
  closeSubmitModal,
  ModalContext,
} from "contexts/ModalContext";
import { RegisterModal } from "components/modals/RegisterModal";
import { SubmitModal } from "components/modals/SubmitModal";
import { StripeRedirect } from "pages/StripeRedirect";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Associate Mono';
    src: url(${AssociateMonoLightWoff}) format('woff'), url(${AssociateMonoLightWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  

  @font-face {
    font-family: 'Associate Mono Bold';
    src: url(${AssociateMonoMediumWoff}) format('woff'), url(${AssociateMonoMediumWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  html {
    background: ${colors.background};
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20.16px;
    font-family: Associate Mono, sans-serif;
    background: ${colors.background};
    color: ${colors.primary};
  }

  main {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  h2 {
    font-family: Associate Mono Bold, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 30.24px;
  }

  h3 {
    font-family: Associate Mono Bold, sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 25.2px;
  }

  .Toastify__toast-body {
    padding-right: 20px;
  }
`;

export const App: React.FC = () => {
  const { state, dispatch } = React.useContext(ModalContext);
  const fetchingDraftRef = React.useRef(false);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <GlobalStyle />
        <Header />

        <Switch>
          <Route path="/stripe-checkout">
            <StripeRedirect />
          </Route>

          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>

          <Route path="/success">
            <SuccessPage />
          </Route>

          <Route path="/password-recovery" exact>
            <ForgotPassword />
          </Route>

          <Route path="/password-recovery-success" exact>
            <ForgotPasswordSuccess />
          </Route>

          <Route path="/change-password">
            <ChangePassword />
          </Route>

          <Route path="/change-password-success" exact>
            <ChangePasswordSuccess />
          </Route>

          <LoggedIn>
            <Route path="/" exact>
              <Homepage />
            </Route>

            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_ID,
                currency: "USD",
                "disable-funding": "card",
              }}
            >
              <Route path="/awards-register">
                <AwardsRegister fetchingDraftRef={fetchingDraftRef} />
                {/* <RegistrationEnded /> */}
              </Route>

              <Route path={routes.entriesList} exact>
                <EntriesList fetchingDraftRef={fetchingDraftRef} />
              </Route>
            </PayPalScriptProvider>

            <Route path="/edit">
              <EditForm />
              {/* <RegistrationEnded /> */}
            </Route>

            <Route path={routes.profile} exact>
              <MyProfile />
            </Route>
          </LoggedIn>

          <Redirect to="/login" />
        </Switch>

        <Footer />
        <RegisterModal
          isOpen={state.isRegisterModalOpen}
          closeModal={() => dispatch(closeRegisterModal())}
          username={state.username}
        />
        <SubmitModal
          isOpen={state.isSubmitModalOpen}
          closeModal={() => dispatch(closeSubmitModal())}
          projectName={state.projectName}
          categories={state.categories}
        />
      </AuthContextProvider>
    </BrowserRouter>
  );
};
