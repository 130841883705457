import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { PayPalButtons } from '@paypal/react-paypal-js';
import Cookies from 'js-cookie';

import {
  formatPrice,
  getDiscountedPrice,
  getEntryPrice,
  initialValues as formInitialValues,
  mapEntryToForm,
  validationSchema,
} from '../helpers';
import { CreateOrderActions, OnApproveActions, OnClickActions } from 'typings/payPal';
import { PayPalInfoModal } from 'components/form/PayPalInfoModal';
import { StepBlock } from 'components/register/StepBlock';
import * as S from '../styles';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { saveEntry } from '../services';
import { ProjectStatus } from 'typings/registerForm';
import { apiUrls, routes } from 'variables';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { apiClient } from 'helpers/APIClient';
import { ModalContext, openSubmitModal } from 'contexts/ModalContext';
import { CardButton } from 'components/form/CardButton';

export const AwardsRegister: React.FC<{ fetchingDraftRef: React.MutableRefObject<boolean> }> = ({
  fetchingDraftRef,
}) => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [allowedSteps, setAllowedSteps] = React.useState<{ [key: string]: boolean }>({
    '1': true,
    '2': false,
    '3': false,
  });
  const [initialValues, setInitialValues] = React.useState(formInitialValues);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetchingEntry, setIsFetchingEntry] = React.useState(false);
  const [isFetchingCard, setIsFetchingCard] = React.useState(false);
  const [isPaymentMessageVisible, setIsPaymentMessageVisible] = React.useState(false);
  const [fee, setFee] = React.useState({ final: '0.00', regular: '0.00', discount: '0.00' });
  const [draftProjectId, setDraftProjectId] = React.useState<string>('');
  const valuesRef = React.useRef(initialValues);
  const shouldSaveDraftRef = React.useRef(true);
  const dirtyRef = React.useRef(false);

  const { dispatch } = React.useContext(ModalContext);

  const history = useHistory();
  const { search } = useLocation();

  const projectId = search.slice(4);

  const stepBlocks = [
    { step: 1, name: 'Contact & categories' },
    { step: 2, name: 'Project details' },
    { step: 3, name: 'Summary' },
  ];

  React.useEffect(() => {
    shouldSaveDraftRef.current = true;
  }, []);

  React.useEffect(() => {
    setIsFetchingEntry(true);

    if (!projectId) {
      apiClient
        .post(`${apiUrls.submitEntry}`, { status: ProjectStatus.Draft, projectName: 'Unnamed project' })
        .then(({ data }) => setDraftProjectId(data.id))
        .then(() => setIsFetchingEntry(false))
        .catch(() => {
          history.push(routes.awardsRegister);
          history.go(0);
          toast('Unable to create a new entry. Please try again later.', toastSettings);
        });

      return;
    }

    apiClient
      .get(`${apiUrls.getEntryDetails}${projectId}`)
      .then(({ data }) => {
        if (data.status === ProjectStatus.Submitted) {
          history.push(routes.awardsRegister);
          history.go(0);
        }

        mapEntryToForm(data, setInitialValues);
        setAllowedSteps({
          '1': true,
          '2': true,
          '3': true,
        });
      })
      .then(() => setIsFetchingEntry(false))
      .catch(() => {
        history.push(routes.awardsRegister);
        history.go(0);
      });
  }, []);

  if (isFetchingEntry) {
    return (
      <S.LoaderWrapper>
        <S.Loader>
          <i className="fa fa-circle-o-notch fa-spin" />
        </S.Loader>
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.FormHeader>
        <S.Title>Preparation Entry to the Awards</S.Title>

        <S.StepBlocks>
          {stepBlocks.map(({ step, name }) => (
            <StepBlock
              key={step}
              step={step}
              name={name}
              isActive={step === currentStep}
              onClick={() => {
                if (allowedSteps[step]) {
                  setCurrentStep(step);
                }
              }}
            />
          ))}
        </S.StepBlocks>
      </S.FormHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={() => undefined}
      >
        {({
          errors,
          values,
          validateForm,
          setErrors,
          setTouched,
          touched,
          handleChange,
          setFieldValue,
          setFieldError,
          dirty,
        }) => {
          React.useEffect(() => {
            valuesRef.current = values;
          }, [values]);

          React.useEffect(() => {
            dirtyRef.current = dirty;
          }, [dirty]);

          const saveDraft = () => {
            if (!projectId && !draftProjectId) {
              return;
            }

            if (!shouldSaveDraftRef.current || !dirtyRef.current) {
              return;
            }

            fetchingDraftRef.current = true;

            return saveEntry(valuesRef.current, ProjectStatus.Draft, projectId || draftProjectId, true)
              .then(() => toast('Draft saved.', toastSettings))
              .catch(() => toast('Failed to save draft.', toastSettings))
              .finally(() => (fetchingDraftRef.current = false));
          };

          const cardHandler = () => {
            setIsFetchingCard(true);

            validateForm()
              .then(setErrors)
              .then(() => {
                if (Object.keys(errors).length !== 0) {
                  toast('Please fill all required fields and try again', toastSettings);

                  return Promise.reject('formError');
                }

                Cookies.set('projectDetails', values);

                if (projectId) {
                  return { id: projectId };
                }

                return saveEntry(values, ProjectStatus.NotPaid, projectId);
              })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .then((entry: any) =>
                apiClient.post(`${apiUrls.generateStrapiLink}`, {
                  price: Number(fee.final),
                  project_entry_ids: [entry.id],
                }),
              )
              .then(({ data }) => (window.location.href = data.url))
              .catch(err => {
                if (err !== 'formError') {
                  toast('Payment failed. Please try again.', toastSettings);
                }

                setIsFetchingCard(false);
              });
          };

          React.useEffect(
            () => () => {
              saveDraft();
            },
            [],
          );

          React.useEffect(() => {
            if (!values.projectCategory || values.participantCategory === '') {
              return;
            }

            const categoryNumbers = values.projectCategory.length;
            const price = categoryNumbers * getEntryPrice(values.participantCategory);
            const discountedPrice = getDiscountedPrice(price, categoryNumbers);

            setFee({
              final: formatPrice(discountedPrice),
              regular: formatPrice(price),
              discount: formatPrice(price - discountedPrice),
            });
          }, [values]);

          const step1validation = () => {
            const step1Touched = {
              name: true,
              email: true,
              participantCategory: true,
              projectCategory: true,
            };

            validateForm()
              .then(setErrors)
              .then(() => setTouched(step1Touched))
              .then(() => {
                const stepErrors = [
                  errors.name,
                  errors.email,
                  errors.participantCategory,
                  errors.projectCategory,
                ].filter(err => err);

                if (stepErrors.length === 0) {
                  setAllowedSteps(prevState => ({ ...prevState, '2': true }));
                  setCurrentStep(2);
                  saveDraft();
                }
              });
          };

          const step2validation = () => {
            const step2Touched = {
              companyName: true,
              website: true,
              designTeam: true,
              projectName: true,
              description: true,
              image1: true,
              country: true,
            };

            validateForm()
              .then(setErrors)
              .then(() => setTouched(step2Touched))
              .then(() => {
                const stepErrors = [
                  errors.companyName,
                  errors.website,
                  errors.designTeam,
                  errors.projectName,
                  errors.description,
                  errors.image1,
                  errors.country,
                ].filter(err => err);

                if (stepErrors.length === 0) {
                  setAllowedSteps(prevState => ({ ...prevState, '3': true }));
                  setCurrentStep(3);
                  saveDraft();
                }
              });
          };

          return (
            <S.StyledForm>
              {currentStep === 1 && (
                <StepOne values={values} errors={errors} touched={touched} handleChange={handleChange} />
              )}

              {currentStep === 2 && (
                <StepTwo
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                />
              )}

              {currentStep === 3 && <StepThree values={values} />}

              <S.FeeAndNextSection>
                <S.Fee>
                  Registration fee: <u>${fee.final}</u>
                </S.Fee>
                <S.FeeParagraph>
                  Your discount: <u>-${fee.discount}</u>
                </S.FeeParagraph>
                <S.FeeParagraph>
                  Regular fee: <u>${fee.regular}</u>
                </S.FeeParagraph>
                {currentStep === 3 ? (
                  <>
                    <S.PayPalWrapper>
                      <PayPalButtons
                        style={{ color: 'white', height: 40 }}
                        createOrder={(data: unknown, actions: CreateOrderActions) =>
                          actions.order.create({
                            purchase_units: [{ amount: { value: fee.final } }],
                            application_context: {
                              shipping_preference: 'NO_SHIPPING',
                            },
                          })
                        }
                        onClick={(_data: unknown, actions: OnClickActions) =>
                          validateForm()
                            .then(setErrors)
                            .then(() => {
                              if (Object.keys(errors).length !== 0) {
                                toast('Please fill all required fields and try again', toastSettings);

                                return actions.reject();
                              }

                              return actions.resolve();
                            })
                        }
                        onApprove={(data: unknown, actions: OnApproveActions) => {
                          shouldSaveDraftRef.current = false;
                          setIsPaymentMessageVisible(true);

                          return actions.order.capture().then(() =>
                            saveEntry(
                              { ...values, charged_amount: Math.ceil(Number(fee.final)) },
                              ProjectStatus.Submitted,
                              projectId,
                            )
                              .then(() => history.push(routes.entriesList))
                              .then(() =>
                                dispatch(openSubmitModal(values.projectName, values.projectCategory)),
                              )
                              .catch(() => {
                                setIsPaymentMessageVisible(false);
                                setIsFetching(false);
                                toast(
                                  'Payment verification failed. Please contact us to verify it.',
                                  toastSettings,
                                );
                              }),
                          );
                        }}
                        onError={() => toast('Payment failed. Please try again', toastSettings)}
                      />
                    </S.PayPalWrapper>
                    <CardButton isFetching={isFetchingCard} onClick={cardHandler} />

                    <S.PayPalDisclaimer>
                      By submitting the payment you confirm you have read and agree with{' '}
                      <S.Link
                        href="https://designeducates.com/guidelines/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Guidelines & Privacy Policy
                      </S.Link>
                    </S.PayPalDisclaimer>
                    <p>You can save your project as a draft and pay later</p>
                    <S.NextButton
                      disabled={isFetching}
                      onClick={() => {
                        setIsFetching(true);
                        saveDraft()
                          ?.then(() => {
                            shouldSaveDraftRef.current = false;
                            history.push(routes.entriesList);
                          })
                          .catch(() => setIsFetching(false));
                      }}
                    >
                      Save draft
                      {isFetching && (
                        <span>
                          <i className="fa fa-circle-o-notch fa-spin" />
                        </span>
                      )}
                    </S.NextButton>
                    <PayPalInfoModal isOpen={isPaymentMessageVisible} />
                  </>
                ) : (
                  <S.NextButton
                    type="button"
                    onClick={() => (currentStep === 1 ? step1validation() : step2validation())}
                  >
                    Save & next step
                  </S.NextButton>
                )}
              </S.FeeAndNextSection>
            </S.StyledForm>
          );
        }}
      </Formik>
    </S.Wrapper>
  );
};
