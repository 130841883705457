export enum ParticipantCategory {
  IndividualStudent = 'INDIVIDUAL_STUDENT',
  TeamOfStudents = 'TEAM_OF_STUDENTS',
  IndividualProfessional = 'INDIVIDUAL_PROFESSIONAL',
  CompanyGreaterThan10Employees = 'COMPANY_GREATER_THAN_10_EMPLOYEES',
  CompanyGreater11To100Employees = 'COMPANY_GREATER_11_100_EMPLOYEES',
  CompanyGreaterThan100Employees = 'COMPANY_GREATER_THAN_100_EMPLOYEES',
}

export enum ProjectCategory {
  ArchitecturalDesign = 'ARCHITECTURAL_DESIGN',
  ProductDesign = 'PRODUCT_DESIGN',
  UniversalDesign = 'UNIVERSAL_DESIGN',
  // ResponsiveDesign = 'RESPONSIVE_DESIGN',
}

export enum ProjectStatus {
  Draft = 'DRAFT',
  NotPaid = 'NOT_PAID',
  Submitted = 'SUBMITTED',
}
