import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'assets/icons/logo.svg';
import { AuthContext, logout } from 'contexts/AuthContext';
import { colors, mediaQueries, routes } from 'variables';
import { MobileHeader } from './MobileHeader';

const Wrapper = styled.header`
  display: flex;
  padding: 20px 14px;
  position: sticky;
  top: 0;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.background};

  ${mediaQueries.lg} {
    background-color: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const Logo = styled.img`
  width: 174px;
  height: 61px;
  cursor: pointer;
`;

const Navbar = styled.div`
  display: none;
  align-items: center;
  padding-right: 60px;
  p {
    margin: 0 0 0 36px;
    white-space: nowrap;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  ${mediaQueries.lg} {
    display: flex;
  }
`;

const CancelParagraph = styled.p`
  cursor: pointer;

  ${mediaQueries.lg} {
    padding-right: 60px;
  }
`;

export const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = React.useContext(AuthContext);

  const notAuthPaths = [
    routes.register,
    routes.login,
    routes.forgotPassword,
    routes.forgotPasswordSuccess,
    routes.changePassword,
    routes.changePasswordSuccess,
  ];

  const displayNavbar =
    notAuthPaths.some(path => path.includes(location.pathname)) && location.pathname !== '/';

  return (
    <Wrapper>
      <a href="https://designeducates.com/">
        <Logo src={logo} alt="Link to DEA main page" />
      </a>

      {!displayNavbar && (
        <>
          {location.pathname === routes.awardsRegister ? (
            <CancelParagraph onClick={() => history.push(routes.entriesList)}>Cancel</CancelParagraph>
          ) : (
            <>
              <Navbar>
                <p onClick={() => history.push(routes.profile)}>{'My profile'}</p>
                <p onClick={() => history.push(routes.entriesList)}>Entries list</p>
                <p onClick={() => history.push(routes.awardsRegister)}>Add entry</p>
                <p style={{ textDecoration: 'none' }}>|</p>
                <p
                  onClick={() => {
                    dispatch(logout());
                    history.push(routes.login);
                  }}
                >
                  Logout
                </p>
              </Navbar>
              <MobileHeader />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};
