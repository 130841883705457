const mediaQ = (size: number) => `@media only screen and (min-width: ${size}px)`;

export const colors = {
  background: '#202020',
  primary: '#FFFFFF',
  secondary: '#8B8B8B',
  error: '#DB3832',
};

export const mediaQueries = {
  xs: mediaQ(320),
  sm: mediaQ(576),
  md: mediaQ(850),
  lg: mediaQ(992),
  xl: mediaQ(1200),
  xxl: mediaQ(1440),
};

export const projectRegex = /project\d+$/;

export const mediaBase = process.env.REACT_APP_MEDIA_URL;
export const apiBase = process.env.REACT_APP_API_URL;

export const apiUrls = {
  step1Create: `${apiBase}form/profile/step-1/`,
  step2Create: `${apiBase}profiles/registration-process/`,
  profileCreate: `${apiBase}profiles/`,
  refresh: `${apiBase}token/refresh/`,
  profileDetails: `${apiBase}profiles/show-details/`,
  login: `${apiBase}token/`,
  register: `${apiBase}profiles/register/`,
  forgotPassword: `${apiBase}profiles/request-reset-password/`,
  resetPassword: `${apiBase}profiles/reset-password/`,
  uploadProjectImage: `${apiBase}awards/upload-project-image/`,
  submitEntry: `${apiBase}awards/submit-entry/`,
  entriesList: `${apiBase}awards/entries/`,
  updateEntry: `${apiBase}awards/update-entry/`,
  updateProfile: `${apiBase}profiles/edit-details/`,
  changePassword: `${apiBase}profiles/change-password/`,
  getEntryDetails: `${apiBase}awards/entry-details/`,
  generateStrapiLink: `${apiBase}payments/generate-url/`,
  validateCoupon: `${apiBase}vouchers/`,
};

export const routes = {
  login: '/login',
  home: '/',
  register: '/register',
  forgotPassword: '/password-recovery',
  changePassword: '/change-password',
  forgotPasswordSuccess: '/password-recovery-success',
  changePasswordSuccess: '/change-password-success',
  entriesList: '/entries',
  profile: '/profile',
  awardsRegister: '/awards-register',
  awardsSuccess: '/success',
  editEntry: '/edit?id=',
  editDraft: '/edit-draft?id=',
};
