import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';

import logo from 'assets/icons/logo.svg';

import { colors, mediaQueries } from 'variables';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 666px;
    padding: 26px 16px 50px 16px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: white;
    color: black;
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;

    ${mediaQueries.md} {
      padding: 36px 36px 100px 61px;
    }
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Logo = styled.img`
  width: 240px;
  height: 78px;
  cursor: pointer;
  filter: invert(1);
  margin-left: -10px;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  align-self: flex-end;
  background-color: transparent;
  color: ${colors.background};
`;

const Header = styled.h2`
  font-family: 'Associate Mono';
  font-weight: bold;
  margin: 46px 0 24px;
`;

const Paragraph = styled.p`
  padding-right: 25px;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  username: string;
}

export const RegisterModal: React.FC<Props> = ({ isOpen, closeModal, username }) => (
  <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
    <ModalStyles />
    <CloseButton onClick={closeModal}>X</CloseButton>
    <Logo src={logo} />

    <Header>Welcome in DEA Members Panel</Header>

    <Paragraph>Dear {username},</Paragraph>

    <Paragraph>
      thank you to registration in DEA Members Panel. In this place you can manage your entries and all
      necessary information.
    </Paragraph>

    <Paragraph>
      We believe that together with all creative people around the world we can for broadening the
      horizons of design that educates topic.
    </Paragraph>

    <Paragraph>
      If you have any concerns, please contact us at
      <br />
      hello@designeducates.com.
    </Paragraph>

    <Paragraph>
      All the best,
      <br />
      DEAwards Team
    </Paragraph>
  </Modal>
);
