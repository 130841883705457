import React from 'react';

export interface ModalState {
  username: string;
  projectName: string;
  categories: string[];
  isRegisterModalOpen: boolean;
  isSubmitModalOpen: boolean;
}

type Action =
  | { type: 'REGISTER_MODAL_OPEN'; payload: { username: string } }
  | { type: 'REGISTER_MODAL_CLOSE' }
  | { type: 'SUBMIT_MODAL_OPEN'; payload: { projectName: string; categories: string[] } }
  | { type: 'SUBMIT_MODAL_CLOSE' };

interface ContextState {
  state: ModalState;
  dispatch: (action: Action) => void;
}

const initialState: ModalState = {
  username: '',
  projectName: '',
  categories: [],
  isRegisterModalOpen: false,
  isSubmitModalOpen: false,
};

const openRegisterModal = (username: string): Action => ({
  type: 'REGISTER_MODAL_OPEN',
  payload: { username },
});

const closeRegisterModal = (): Action => ({
  type: 'REGISTER_MODAL_CLOSE',
});

const openSubmitModal = (projectName: string, categories: string[]): Action => ({
  type: 'SUBMIT_MODAL_OPEN',
  payload: { projectName, categories },
});

const closeSubmitModal = (): Action => ({
  type: 'SUBMIT_MODAL_CLOSE',
});

function modalReducer(state: ModalState, action: Action): ModalState {
  switch (action.type) {
    case 'REGISTER_MODAL_OPEN':
      return { ...state, username: action.payload.username, isRegisterModalOpen: true };

    case 'REGISTER_MODAL_CLOSE':
      return { ...state, isRegisterModalOpen: false };

    case 'SUBMIT_MODAL_OPEN':
      return {
        ...state,
        projectName: action.payload.projectName,
        categories: action.payload.categories,
        isSubmitModalOpen: true,
      };

    case 'SUBMIT_MODAL_CLOSE':
      return {
        ...state,
        isSubmitModalOpen: false,
      };

    default:
      return state;
  }
}

const ModalContext = React.createContext<ContextState>({
  state: initialState,
  dispatch: () => undefined,
});

const ModalContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);

  return <ModalContext.Provider value={{ state, dispatch }}>{children}</ModalContext.Provider>;
};

export {
  ModalContextProvider,
  ModalContext,
  openRegisterModal,
  closeRegisterModal,
  openSubmitModal,
  closeSubmitModal,
};
