import React from 'react';
import styled from 'styled-components';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import { CheckboxGroup } from 'components/form/CheckboxGroup';
import { Input } from 'components/form/Input';
import { Select } from 'components/form/Select';
import { categoryOptions, projectCategoryCheckboxes } from '../helpers';
import { mediaQueries } from 'variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const Title = styled.h3`
  margin: 0 0 32px;
`;

const CategoryTitle = styled(Title)`
  margin-top: 16px;
`;

interface Values {
  name: string;
  email: string;
  company: string;
  participantCategory: string;
  projectCategory: string[];
}

interface Props {
  values: FormikValues;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  handleChange: (e: React.ChangeEvent<unknown>) => void;
}

export const StepOne: React.FC<Props> = ({ values, handleChange, errors, touched }) => (
  <Wrapper>
    <Title>Contact details</Title>

    <Input
      label="Full name"
      name="name"
      value={values.name}
      onChange={handleChange}
      error={errors.name}
      touched={touched.name}
    />

    <Input
      type="email"
      label="Email"
      name="email"
      value={values.email}
      onChange={handleChange}
      error={errors.email}
      touched={touched.email}
    />

    <Input label="Company (optional)" name="company" value={values.company} onChange={handleChange} />

    <Select
      label="Participant category"
      name="participantCategory"
      value={values.participantCategory}
      onChange={handleChange}
      options={categoryOptions}
      initialOption="Select category"
      error={errors.participantCategory}
      touched={touched.participantCategory}
    />

    <CategoryTitle>Project category</CategoryTitle>

    <CheckboxGroup
      checkboxes={projectCategoryCheckboxes}
      name="projectCategory"
      onChange={handleChange}
      error={errors.projectCategory as string}
      touched={touched.projectCategory}
    />
  </Wrapper>
);
