import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'variables';

import cardIcon from 'assets/icons/card.svg';

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 9px 0 16px;
  max-width: 750px;
  height: 40px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #2c2e2e;
  cursor: pointer;

  ${mediaQueries.lg} {
    max-width: 271px;
  }

  span {
    margin-right: 16px;
  }
`;

const Icon = styled.img`
  margin-right: 16px;
`;

interface Props {
  isFetching: boolean;
  onClick: () => void;
}

export const CardButton = ({ isFetching, onClick }: Props): JSX.Element => (
  <Wrapper type="button" disabled={isFetching} onClick={onClick}>
    {isFetching ? (
      <span>
        <i className="fa fa-circle-o-notch fa-spin" />
      </span>
    ) : (
      <Icon src={cardIcon} />
    )}
    Credit or debit card
  </Wrapper>
);
