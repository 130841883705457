import React from 'react';
import styled from 'styled-components';

import { colors } from 'variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const InputField = styled.textarea`
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.primary};
  border-radius: 0;
  box-sizing: border-box;
  background: transparent;
  color: ${colors.primary};
  resize: none;
`;

const ErrorMessage = styled.p`
  margin: -16px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

const Counter = styled.p`
  margin: 0px 0px 0px auto;
`;

interface Props {
  label: string;
  name: string;
  error?: string;
  touched?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  maxLength?: number;
}

export const TextArea: React.FC<Props> = ({
  error,
  label,
  name,
  onChange,
  touched,
  value,
  maxLength = 3000,
}) => (
  <Wrapper>
    <Label htmlFor={name}>{label}</Label>
    <InputField
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      rows={8}
      placeholder={`Maximum ${maxLength} characters`}
      maxLength={maxLength}
    />
    <Counter>
      {value?.length || 0}/{maxLength}
    </Counter>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);
