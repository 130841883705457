import React from 'react';
import Modal from 'react-modal';
import { createGlobalStyle } from 'styled-components';

import { colors } from 'variables';

const ModalStyles = createGlobalStyle`
  .Modal {
    margin: 2rem;
    padding: 1rem 3rem;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    overflow: scroll;
    background-color: ${colors.background};
    color: white;
    outline: none;
    text-align: center;
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
  }
`;

interface Props {
  isOpen: boolean;
}

export const PayPalInfoModal: React.FC<Props> = ({ isOpen }) => (
  <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay">
    <ModalStyles />
    <h2>We are verifying the payment, please do not close the browser</h2>
  </Modal>
);
