import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { apiUrls, mediaQueries, routes } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { Input } from 'components/form/Input';
import {
  ChangePasswordRequestValues,
  initialValues,
  validationSchema,
} from 'helpers/changePasswordForm';
import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const Title = styled.h2`
  margin: 42px 0;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 533px;
`;

const StyledButton = styled(PrimaryButton)`
  width: 213px;
  margin-top: 56px;
`;

export const ChangePassword: React.FC = () => {
  const [isFetching, setIsFetching] = React.useState(false);
  const history = useHistory();
  const { search } = useLocation();

  const token = search.slice(7);

  const onSubmit = (values: ChangePasswordRequestValues) => {
    setIsFetching(true);

    const body = {
      password: values.password,
      token,
    };

    return apiClient
      .post(apiUrls.resetPassword, body)
      .then(() => history.push(routes.changePasswordSuccess))
      .catch(err => {
        err.response.data.errors.forEach(({ message }: { message: string }) =>
          toast(message, toastSettings),
        );
        setIsFetching(false);
      });
  };

  return (
    <Wrapper>
      <Title>Password recovery</Title>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange }) => (
          <StyledForm>
            <Input
              label="New password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              touched={touched.password}
            />

            <Input
              label="Confirm new password"
              name="passwordConfirm"
              type="password"
              value={values.passwordConfirm}
              onChange={handleChange}
              error={errors.passwordConfirm}
              touched={touched.passwordConfirm}
            />

            <StyledButton type="submit" disabled={isFetching}>
              Continue
              {isFetching && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin" />
                </span>
              )}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};
