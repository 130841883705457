import { PrimaryButton } from 'components/common/Buttons';
import styled from 'styled-components';

import { colors, mediaQueries } from 'variables';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

export const SectionHeader = styled.h2`
  font-size: 52px;
  margin: 48px 0 40px;
  line-height: 80.72px;
  text-align: center;

  ${mediaQueries.lg} {
    margin: 128px 0 40px;
    font-size: 72px;
    line-height: 90.72px;
  }
`;

export const RegisterButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 640px;
`;

export const Paragraph = styled.p`
  max-width: 640px;
  text-align: center;
  margin-bottom: 72px;
`;

export const CategoryBlock = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 200px;
  border: none;
  border: 3px solid ${colors.primary};
  border-bottom: none;
  overflow: hidden;

  :nth-of-type(4) {
    border-bottom: 3px solid ${colors.primary};
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    box-sizing: border-box;
    transition: transform 0.35s ease-in-out;
  }

  span {
    font-family: 'Associate Mono Bold';
    font-size: 28px;
    line-height: 40.32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${mediaQueries.md} {
      left: 40px;
      transform: translateY(-50%);
      font-size: 32px;
    }
  }

  :hover {
    img {
      transform: scale(1.1);
    }
  }
`;

export const CategoryBlockImage = styled.img`
  width: 100%;
  height: 200px;
  color: white;

  ${mediaQueries.lg} {
    border: none;
  }
`;

export const Schedule = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-family: 'Associate Mono Bold';
  font-size: 28px;
  line-height: 40.32px;
  width: 100%;

  ${mediaQueries.lg} {
    grid-template-columns: 1fr 1fr 1fr;
    height: 344px;
  }

  ${mediaQueries.xl} {
    font-size: 32px;
  }
`;

const ScheduleBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 344px;
  transition: filter 0.15s ease-in-out;

  :hover {
    filter: brightness(1.25);
  }
`;

export const EarlySchedule = styled(ScheduleBlock)`
  border: 3px solid ${colors.primary};
  background-color: #007233;
`;

export const RegularSchedule = styled(ScheduleBlock)`
  border-left: 3px solid ${colors.primary};
  border-right: 3px solid ${colors.primary};
  background-color: #004d2d;

  ${mediaQueries.lg} {
    border: none;
    border-top: 3px solid ${colors.primary};
    border-bottom: 3px solid ${colors.primary};
  }
`;

export const LateSchedule = styled(ScheduleBlock)`
  border: 3px solid ${colors.primary};
  background-color: #1b3129;
`;

export const Link = styled.a`
  margin-top: -42px;
  margin-bottom: 92px;
  font-family: 'Associate Mono Bold';
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: ${colors.primary};
`;

export const Image = styled.img`
  width: 100%;
  max-height: 746px;
  object-fit: cover;
`;
