import { apiClient } from 'helpers/APIClient';
import { getDiscountedPrice, getEntryPrice, formatPrice } from 'pages/awardsRegister/helpers';
import { ParticipantCategory, ProjectCategory, ProjectStatus } from 'typings/registerForm';
import { apiUrls } from 'variables';

export const getProjectName = (name: string): string =>
  name.length > 25 ? `${name.substring(0, 25)}...` : name;

export const getFee = (categories: ProjectCategory[], participant: ParticipantCategory): string => {
  const categoryNumbers = categories.length;
  const price = categoryNumbers * getEntryPrice(participant);
  const discountedPrice = getDiscountedPrice(price, categoryNumbers);

  return formatPrice(discountedPrice);
};

export const payProject = (id: number, amount: number): Promise<void> =>
  apiClient
    .patch(`${apiUrls.updateEntry}${id}/`, {
      status: ProjectStatus.Submitted,
      charged_amount: Math.ceil(amount),
    })
    .then(res => res.data);

export const removeProject = (id: number): Promise<void> =>
  apiClient.patch(`${apiUrls.updateEntry}${id}/`, { isDeleted: true }).then(res => res.data);
