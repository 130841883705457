import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { apiUrls, mediaQueries, routes } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { Input } from '../../components/form/Input';
import {
  ForgotPasswordRequestValues,
  initialValues,
  validationSchema,
} from 'helpers/forgotPasswordForm';
import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const Title = styled.h2`
  margin: 42px 0;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 533px;
`;

const StyledButton = styled(PrimaryButton)`
  width: 213px;
  margin-top: 56px;
`;

const AdditionalInfo = styled.p`
  margin: 61px 0 108px;
`;

export const ForgotPassword: React.FC = () => {
  const [isFetching, setIsFetching] = React.useState(false);
  const history = useHistory();

  const onSubmit = (values: ForgotPasswordRequestValues) => {
    setIsFetching(true);

    apiClient
      .post(apiUrls.forgotPassword, values)
      .then(() => history.push(routes.forgotPasswordSuccess))
      .catch(err => {
        err.response.data.errors.forEach(({ message }: { message: string }) =>
          toast(message, toastSettings),
        );
        setIsFetching(false);
      });
  };

  return (
    <Wrapper>
      <Title>Password recovery</Title>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange }) => (
          <StyledForm>
            <Input
              label="Enter the email you're using for your account."
              name="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              touched={touched.email}
            />

            <StyledButton type="submit" disabled={isFetching}>
              Continue
              {isFetching && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin" />
                </span>
              )}
            </StyledButton>

            <AdditionalInfo>
              <u style={{ cursor: 'pointer' }} onClick={() => history.push('/login')}>
                Back to login
              </u>
            </AdditionalInfo>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};
