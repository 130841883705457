import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';
import { apiUrls, routes } from 'variables';
import * as S from './styles';
import { PrimaryButton } from 'components/common/Buttons';
import { Entry } from 'typings/entries';
import { PaidTable } from './PaidTable';
import { DraftTable } from './DraftTable';
import { ProjectStatus } from 'typings/registerForm';

export const EntriesList = ({
  fetchingDraftRef,
}: {
  fetchingDraftRef: React.MutableRefObject<boolean>;
}): JSX.Element => {
  const [entries, setEntries] = React.useState<Entry[]>([]);
  const [isFetching, setIsFetching] = React.useState(true);

  const history = useHistory();

  const updateTable = (): void => {
    setIsFetching(true);

    apiClient
      .get(apiUrls.entriesList)
      .then(({ data }) =>
        // TODO: refactor this to use api config
        setEntries(data.filter((entry: { projectEdition: string }) => entry.projectEdition === '2024')),
      )
      .then(() => setIsFetching(false))
      .catch(() => {
        toast('Unable to get entries list. Please try again later.', toastSettings);
      });
  };

  React.useEffect(() => {
    updateTable();
  }, [fetchingDraftRef.current]);

  const paidEntries = entries.filter(entry => entry.status === ProjectStatus.Submitted);
  const draftEntries = entries.filter(entry => entry.status !== ProjectStatus.Submitted);

  return (
    <S.Wrapper>
      <S.Title>My entry list</S.Title>

      {isFetching || fetchingDraftRef.current ? (
        <S.Loader>
          <i className="fa fa-circle-o-notch fa-spin" />
        </S.Loader>
      ) : (
        <>
          {entries.length !== 0 ? (
            <>
              <S.NewEntryLink to={routes.awardsRegister}>
                Add new entry <span>+</span>
              </S.NewEntryLink>
              {paidEntries.length !== 0 && <PaidTable entries={paidEntries} />}
              {draftEntries.length !== 0 && (
                <DraftTable
                  entries={draftEntries}
                  displayHeader={paidEntries.length === 0}
                  updateTable={updateTable}
                />
              )}
            </>
          ) : (
            <>
              <S.InfoParagraph>
                Your list is empty, let’s start preparing your first project
              </S.InfoParagraph>
              <PrimaryButton onClick={() => history.push(routes.awardsRegister)}>
                Add first entry
              </PrimaryButton>
            </>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
