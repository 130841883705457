import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import Cookies from 'js-cookie';
import { ModalContext, openSubmitModal } from 'contexts/ModalContext';

export const StripeRedirect = (): JSX.Element => {
  const history = useHistory();
  const { dispatch } = React.useContext(ModalContext);

  React.useEffect(() => {
    const values = Cookies.getJSON('projectDetails');
    Cookies.remove('projectDetails');

    if (values) {
      dispatch(openSubmitModal(values.projectName, values.projectCategory));
    } else {
      history.location.search.includes('success=true')
        ? toast('Payment successful', toastSettings)
        : toast('Payment failed', toastSettings);
    }

    history.push('/entries');
  }, []);

  return <div />;
};
