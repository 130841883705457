import React from 'react';
import styled from 'styled-components';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import { Input } from 'components/form/Input';
import { mediaQueries } from 'variables';
import { TextArea } from 'components/form/TextArea';
import { ImageUpload } from 'components/form/ImageUpload';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const Title = styled.h3`
  margin: 0 0 32px;

  :not(:first-child) {
    margin-top: 16px;
  }
`;

interface Values {
  companyName: string;
  website: string;
  country: string;
  designTeam: string;
  projectName: string;
  client?: string;
  location?: string;
  description: string;
  image1: string;
  credits1: string;
  image2: string;
  credits2: string;
  image3: string;
  credits3: string;
  image4: string;
  credits4: string;
  image5: string;
  credits5: string;
}

interface Props {
  values: FormikValues;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  handleChange: (e: React.ChangeEvent<unknown>) => void;
  setFieldValue: (field: string, value: unknown) => void;
  setFieldError: (field: string, value: string | undefined) => void;
}

export const StepTwo: React.FC<Props> = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  setFieldError,
}) => (
  <Wrapper>
    <Title>Team information</Title>

    <Input
      label="Company name"
      name="companyName"
      value={values.companyName}
      onChange={handleChange}
      error={errors.companyName}
      touched={touched.companyName}
    />
    <Input
      label="Website (optional)"
      name="website"
      value={values.website}
      onChange={handleChange}
      error={errors.website}
      touched={touched.website}
    />
    <Input
      label="Country"
      name="country"
      value={values.country}
      onChange={handleChange}
      error={errors.country}
      touched={touched.country}
    />
    <TextArea
      label="Design team"
      name="designTeam"
      value={values.designTeam}
      onChange={handleChange}
      error={errors.designTeam}
      touched={touched.designTeam}
      maxLength={200}
    />

    <Title>Project details</Title>

    <Input
      label="Project name"
      name="projectName"
      value={values.projectName}
      onChange={handleChange}
      error={errors.projectName}
      touched={touched.projectName}
    />
    <Input
      label="Client (optional)"
      name="client"
      value={values.client}
      onChange={handleChange}
      error={errors.client}
      touched={touched.client}
    />
    <Input
      label="Location (optional)"
      name="location"
      value={values.location}
      onChange={handleChange}
      error={errors.location}
      touched={touched.location}
    />
    <TextArea
      label="Project description"
      name="description"
      value={values.description}
      onChange={handleChange}
      error={errors.description}
      touched={touched.description}
      maxLength={2000}
    />

    <Title>Project images</Title>

    <ImageUpload
      setFieldValue={setFieldValue}
      name="image1"
      label="Entry featured image (will present in main promotional elements)"
      error={errors.image1}
      setFieldError={setFieldError}
      value={values.image1}
    />
    <Input
      label="Credits"
      name="credits1"
      value={values.credits1}
      onChange={handleChange}
      error={errors.credits1}
      touched={touched.credits1}
    />

    <ImageUpload
      setFieldValue={setFieldValue}
      name="image2"
      label="Entry image 2"
      setFieldError={setFieldError}
      value={values.image2}
    />
    <Input
      label="Credits"
      name="credits2"
      value={values.credits2}
      onChange={handleChange}
      error={errors.credits2}
      touched={touched.credits2}
    />
    <ImageUpload
      setFieldValue={setFieldValue}
      name="image3"
      label="Entry image 3"
      setFieldError={setFieldError}
      value={values.image3}
    />
    <Input
      label="Credits"
      name="credits3"
      value={values.credits3}
      onChange={handleChange}
      error={errors.credits3}
      touched={touched.credits3}
    />
    <ImageUpload
      setFieldValue={setFieldValue}
      name="image4"
      label="Entry image 4"
      setFieldError={setFieldError}
      value={values.image4}
    />
    <Input
      label="Credits"
      name="credits4"
      value={values.credits4}
      onChange={handleChange}
      error={errors.credits4}
      touched={touched.credits4}
    />
    <ImageUpload
      setFieldValue={setFieldValue}
      name="image5"
      label="Entry image 5"
      setFieldError={setFieldError}
      value={values.image5}
    />
    <Input
      label="Credits"
      name="credits5"
      value={values.credits5}
      onChange={handleChange}
      error={errors.credits5}
      touched={touched.credits5}
    />
  </Wrapper>
);
