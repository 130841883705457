import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { colors } from 'variables';

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid ${colors.primary};
  background-color: ${colors.background};
  box-sizing: border-box;

  :after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Label = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ ${Checkmark} {
    background-color: ${colors.primary};
  }

  input:checked ~ ${Checkmark}:after {
    display: block;
  }

  ${Checkmark}:after {
    left: 5px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid ${colors.background};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const ErrorMessage = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

interface Props {
  label: string | JSX.Element;
  name: string;
  value: string | boolean;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  error?: string;
  touched?: boolean;
}

export const SingleCheckbox: React.FC<Props> = ({ name, label, value, onChange, error, touched }) => (
  <Wrapper>
    <Label>
      {label}
      <Field type="checkbox" id={name} name={name} value={value} onChange={onChange} checked={value} />
      <Checkmark />
    </Label>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);
