import * as Yup from 'yup';

export interface RegisterRequestValues {
  email: string;
  password: string;
  name: string;
  terms: boolean;
  newsletter: boolean;
}

export const initialValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirm: '',
  terms: false,
  newsletter: false,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required field'),
  email: Yup.string().email('Invalid email').required('Required field'),
  password: Yup.string().required('Required field'),
  passwordConfirm: Yup.string()
    .required('Required field')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  terms: Yup.bool().oneOf([true], 'Field must be checked'),
});
