import React from 'react';
import { Entry } from 'typings/entries';

import { StatusTag } from './StatusTag';
import * as S from './styles';
import { getFee, getProjectName } from './helpers';
import { Categories } from './Categories';
import { useHistory } from 'react-router-dom';
import { routes } from 'variables';

interface Props {
  entries: Entry[];
}

export const PaidTable = ({ entries }: Props): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <S.TableWrapper>
        <S.Table>
          <S.HeaderCell>Project name</S.HeaderCell>
          <S.HeaderCell>Categories</S.HeaderCell>
          <S.HeaderCell>Fee</S.HeaderCell>
          <S.HeaderCell>Status</S.HeaderCell>
          <S.HeaderCell></S.HeaderCell>

          {entries.map(entry => (
            <React.Fragment key={entry.projectName}>
              <S.Cell>{getProjectName(entry.projectName)}</S.Cell>
              <S.Cell>
                <Categories categories={entry.awardCategory} />
              </S.Cell>
              <S.Cell>${getFee(entry.awardCategory, entry.participantCategory)}</S.Cell>
              <S.Cell>
                <StatusTag status={entry.status} />
              </S.Cell>
              <S.ActionCell>
                <S.Action onClick={() => history.push(`${routes.editEntry}${entry.id}`)}>Edit</S.Action>
                <S.HiddenAction>Delete</S.HiddenAction>
              </S.ActionCell>
            </React.Fragment>
          ))}
        </S.Table>
      </S.TableWrapper>
      <S.PaidTableInfo>* You can edit your entries until Submissions Deadline</S.PaidTableInfo>
    </>
  );
};
