import React from 'react';
import styled from 'styled-components';

import { mediaBase, mediaQueries } from 'variables';
import { categoryOptions, projectCategoryCheckboxes } from '../helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const Title = styled.h3`
  margin: 0 0 16px;

  :not(:first-child) {
    margin-top: 48px;
  }
`;

const Detail = styled.p`
  white-space: pre-line;
  word-wrap: break-word;
`;

const Image = styled.img`
  margin-top: 16px;
  width: 100%;
  object-fit: contain;
`;

interface Values {
  name: string;
  email: string;
  company: string;
  participantCategory: string;
  projectCategory: string[];
  companyName: string;
  website: string;
  country: string;
  designTeam: string;
  projectName: string;
  client?: string;
  location?: string;
  description: string;
  image1: string;
  credits1: string;
  image2: string;
  credits2: string;
  image3: string;
  credits3: string;
  image4: string;
  credits4: string;
  image5: string;
  credits5: string;
  [key: string]: unknown;
}

interface Props {
  values: Values;
}

export const StepThree = ({ values }: Props): JSX.Element => {
  const contactDetails = [
    { label: 'Full name', value: values.name },
    { label: 'Email', value: values.email },
    { label: 'Country', value: values.country },
    {
      label: 'Participant category',
      value: categoryOptions.find(({ value }) => values.participantCategory === value)?.label,
    },
  ];

  const projectInfo = [
    {
      label: 'Award categories',
      value: values.projectCategory
        .map(category => projectCategoryCheckboxes.find(({ value }) => value === category)?.label)
        .join(', '),
    },
    { label: 'Project name', value: values.projectName },
    { label: 'Location', value: values.location },
  ];

  const projectDescription = [
    { label: 'Description', value: values.description },
    { label: 'Design team', value: values.designTeam },
  ];

  const images = Array.from(Array(5).keys())
    .map(key => key + 1)
    .map(key => ({ src: values[`image${key}`], credits: values[`credits${key}`] }));

  return (
    <Wrapper>
      <Title>Contact details</Title>
      {contactDetails.map(
        detail =>
          detail.value && (
            <Detail key={detail.label}>
              <u>{detail.label}:</u> {detail.value}
            </Detail>
          ),
      )}

      <Title>Project(s) to submit</Title>
      {projectInfo.map(
        detail =>
          detail.value && (
            <Detail key={detail.label}>
              <u>{detail.label}:</u> {detail.value}
            </Detail>
          ),
      )}
      {projectDescription.map(
        detail =>
          detail.value && (
            <Detail key={detail.label}>
              <u>{detail.label}:</u>
              <br />
              <br />
              {detail.value}
            </Detail>
          ),
      )}
      <br />
      <br />
      {images.map(
        image =>
          image.src && (
            <React.Fragment key={image.src as string}>
              <Image src={`${mediaBase}${image.src}`} />
              {image.credits && <Detail>Photo credit: {image.credits}</Detail>}
            </React.Fragment>
          ),
      )}
    </Wrapper>
  );
};
