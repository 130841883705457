import React from 'react';
import ReactTooltip from 'react-tooltip';

import { projectCategoryCheckboxes } from 'pages/awardsRegister/helpers';
import * as S from './styles';

interface Props {
  categories: string[];
}

export const Categories = ({ categories }: Props): JSX.Element => {
  const categoryNames = categories.map(
    category => projectCategoryCheckboxes.find(({ value }) => value === category)?.label,
  );

  return (
    <S.Cell data-tip={categoryNames.join(', ')}>
      {categoryNames.length > 2
        ? `${categoryNames.slice(0, 2).join(', ')}, +${categoryNames.length - 2}`
        : categoryNames.join(', ')}
      <ReactTooltip />
    </S.Cell>
  );
};
