import React from 'react';
import { ProjectStatus } from 'typings/registerForm';

interface Props {
  status: string;
}

export const StatusTag = ({ status }: Props): JSX.Element => {
  const paid = status === ProjectStatus.Submitted;

  return <p>{paid ? 'Paid' : 'Draft'}</p>;
};
