import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ModalContextProvider } from 'contexts/ModalContext';
import { App } from './App';

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <ModalContextProvider>
      <App />
    </ModalContextProvider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root'),
);
