import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';

import logo from 'assets/icons/logo.svg';

import { projectCategoryCheckboxes } from 'pages/awardsRegister/helpers';
import { colors, mediaQueries } from 'variables';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 666px;
    padding: 26px 16px 50px 16px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: white;
    color: black;
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;

    ${mediaQueries.md} {
      padding: 36px 36px 100px 61px;
    }
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Logo = styled.img`
  width: 240px;
  height: 78px;
  cursor: pointer;
  filter: invert(1);
  margin-left: -10px;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  align-self: flex-end;
  background-color: transparent;
  color: ${colors.background};
`;

const Header = styled.h2`
  font-family: 'Associate Mono';
  font-weight: bold;
  margin: 46px 0 24px;
`;

const Paragraph = styled.p`
  padding-right: 25px;
`;

const ProjectName = styled.span`
  font-family: 'Associate Mono Bold';
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  projectName: string;
  categories: string[];
}

export const SubmitModal: React.FC<Props> = ({ isOpen, closeModal, projectName, categories }) => (
  <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
    <ModalStyles />
    <CloseButton onClick={closeModal}>X</CloseButton>
    <Logo src={logo} />

    <Header>Thank you for joining the awards!</Header>

    <Paragraph>
      Thank you very much for the registration project for the Design Educates Awards 2024! We are
      excited to see you in the group of participants and will be looking forward to seeing your designs
      that educate!
    </Paragraph>

    <Paragraph>
      <ProjectName>{projectName}</ProjectName> -{' '}
      {categories
        .map(category => projectCategoryCheckboxes.find(({ value }) => value === category)?.label)
        .join(', ')}
    </Paragraph>

    <Paragraph>
      Meanwhile, if you have any questions, please do not hesitate to contact us at
      hello@designeducates.com. Please check the main website of the Awards to learn more how to submit
      your designs
    </Paragraph>

    {/* <Paragraph>
      You can edit your entry whenever you want until:
      <br />
      2nd January 2022
    </Paragraph> */}
  </Modal>
);
