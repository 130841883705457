import { PrimaryButton } from 'components/common/Buttons';
import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { apiUrls, colors } from 'variables';

const Wrapper = styled.div`
  max-width: 320px;
  width: 100%;
  margin-bottom: 42px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  u {
    cursor: pointer;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const Input = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.primary};
  box-sizing: border-box;
  background: transparent;
  color: ${colors.primary};
`;

const ErrorMessage = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

const RealizeButton = styled(PrimaryButton)`
  margin: 0 0 40px;
  width: 208px;
`;

interface Props {
  setDiscount: (discount: number) => void;
}

export const CouponSection = ({ setDiscount }: Props): JSX.Element => {
  const [isSectionExpanded, setIsSectionExpanded] = React.useState(false);
  const [couponValue, setCouponValue] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const onClick = (code: string) => {
    setIsFetching(true);
    setErrorMessage('');

    apiClient
      .post(`${apiUrls.validateCoupon}`, { code })
      .then(({ data }) => setDiscount(data.discount))
      .then(() => toast('Discount coupon activated', toastSettings))
      .then(() => setIsSectionExpanded(false))
      .catch(() => setErrorMessage('Invalid coupon code'))
      .finally(() => setIsFetching(false));
  };

  return (
    <Wrapper>
      <Header>
        <h3>Rabat coupon</h3>
        <u onClick={() => setIsSectionExpanded(!isSectionExpanded)}>
          {isSectionExpanded ? 'Hide' : 'Add'}
        </u>
      </Header>
      {isSectionExpanded && (
        <Section>
          <InputWrapper>
            <Label>Add rabat coupon</Label>
            <Input onChange={e => setCouponValue(e.target.value)} />
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </InputWrapper>
          <RealizeButton disabled={isFetching} onClick={() => onClick(couponValue)}>
            Realize
            {isFetching && (
              <span style={{ marginLeft: '16px' }}>
                <i className="fa fa-circle-o-notch fa-spin" />
              </span>
            )}
          </RealizeButton>
        </Section>
      )}
    </Wrapper>
  );
};
