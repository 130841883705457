import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { colors, mediaQueries, routes } from 'variables';
import { AuthContext, logout } from 'contexts/AuthContext';

const MenuContainer = styled.div`
  display: block;
  width: 1.7rem;
  height: 1.5rem;
  left: 1rem;
  overflow: hidden;

  ${mediaQueries.sm} {
    width: 2rem;
    left: 3rem;
  }
  ${mediaQueries.lg} {
    display: none;
  }
`;

const MenuIcon = styled.div<{ isOpen: boolean }>`
  position: relative;

  :after,
  :before,
  div {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.primary};
    border-radius: 0.3rem;
    content: '';
    height: 2px;
    width: 100%;
    transition: ${props =>
      props.isOpen
        ? 'top 0.2s ease-in-out, transform 0.2s ease-in-out 0.2s'
        : 'transform 0.2s ease-in-out, top 0.2s ease-in-out 0.2s'};

    ${mediaQueries.sm} {
      height: 3px;
    }
  }

  div {
    top: ${props => (props.isOpen ? 'calc(0.75rem - 1.5px)' : '0')};
    transform: ${props => props.isOpen && 'rotate(-45deg)'};
  }

  :after {
    top: ${props => (props.isOpen ? 'calc(0.75rem - 1.5px)' : 'calc(1.5rem - 3px)')};
    transform: ${props => props.isOpen && 'rotate(45deg)'};
  }

  :before {
    top: calc(0.75rem - 1.5px);
    opacity: ${props => props.isOpen && '0'};
  }
`;

const NavigationMenu = styled.nav<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 2;
  position: absolute;
  max-height: calc(100vh - 7.5rem);
  top: 100px;
  color: ${colors.primary};
  background-color: ${colors.background};
  text-transform: none;
  width: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid ${colors.primary};
  ${mediaQueries.lg} {
    display: none;
  }
`;

const StyledLink = styled.li`
  padding: 0.8rem 1.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid;
  text-decoration: none;
  transition: 0.2s background-color ease-out;
  width: 100%;
  list-style: none;
  color: ${colors.primary};

  ${mediaQueries.sm} {
    padding: 0.8rem 3rem;
  }

  :hover {
    cursor: pointer;
    background-color: ${colors.secondary};
  }

  :last-child {
    font-family: 'Associate Mono Bold';
  }
`;

const links = [
  { route: routes.profile, text: 'My profile' },
  { route: routes.entriesList, text: 'Entries list' },
  { route: routes.awardsRegister, text: 'Add entry' },
];

export const MobileHeader = (): JSX.Element => {
  const history = useHistory();
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = React.useState(false);
  const { dispatch } = React.useContext(AuthContext);

  return (
    <>
      <MenuContainer onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}>
        <MenuIcon isOpen={isNavigationMenuOpen}>
          <div></div>
        </MenuIcon>
      </MenuContainer>
      <NavigationMenu isOpen={isNavigationMenuOpen}>
        {links.map(link => (
          <StyledLink
            key={link.text}
            onClick={() => {
              setIsNavigationMenuOpen(false);
              history.push(link.route);
            }}
          >
            {link.text}
          </StyledLink>
        ))}
        <StyledLink
          onClick={() => {
            setIsNavigationMenuOpen(false);
            dispatch(logout());
            history.push(routes.login);
          }}
        >
          Logout
        </StyledLink>
      </NavigationMenu>
    </>
  );
};
