import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PrimaryButton } from 'components/common/Buttons';
import { mediaQueries } from 'variables';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const Title = styled.h2`
  margin: 42px 0 0;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

const InfoParagraph = styled.p`
  margin-top: 40px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22.68px;
  max-width: 640px;
`;

const HomepageButton = styled(PrimaryButton)`
  width: 100%;
  margin: 40px 0 181px;

  ${mediaQueries.sm} {
    width: auto;
  }
`;

export const ForgotPasswordSuccess: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Title>Thank you!</Title>

      <InfoParagraph>
        If an account for given email exists you will get an password reset instructions to your email
        address. If no email is received within minutes, check that the submitted address is correct.
      </InfoParagraph>

      <HomepageButton onClick={() => history.push('/login')}>Back to login</HomepageButton>
    </Wrapper>
  );
};
