import React from 'react';
import styled from 'styled-components';

import { colors, mediaQueries } from 'variables';

interface WrapperProps {
  isActive?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  margin-top: -3px;
  font-size: 18px;
  line-height: 22.68px;
  color: ${({ isActive }) => (isActive ? colors.primary : colors.secondary)};
  border: 3px solid ${({ isActive }) => (isActive ? colors.primary : colors.secondary)};
  box-sizing: border-box;
  padding: 18px 24px;
  z-index: ${({ isActive }) => (isActive ? 2 : 1)};
  width: 100%;
  cursor: pointer;
  position: relative;

  ${mediaQueries.lg} {
    :not(:first-child) {
      margin-left: -3px;
    }
  }
`;

interface Props extends WrapperProps {
  step: number;
  name: string;
  onClick: () => void;
}

export const StepBlock: React.FC<Props> = ({ step, name, isActive, onClick }) => (
  <Wrapper isActive={isActive} onClick={onClick}>
    {step}. {name}
  </Wrapper>
);
