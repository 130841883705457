import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import { Entry } from 'typings/entries';
import { PayPalButtons } from '@paypal/react-paypal-js';

import { CreateOrderActions, OnApproveActions } from 'typings/payPal';

import { apiUrls, colors, mediaQueries } from 'variables';
import { getProjectName, getFee, payProject } from './helpers';
import * as S from './styles';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { CardButton } from 'components/form/CardButton';
import { apiClient } from 'helpers/APIClient';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 666px;
    padding: 26px 16px 50px 16px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: ${colors.background};
    color: white;
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;

    ${mediaQueries.md} {
      padding: 36px 36px 100px 61px;
    }
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  align-self: flex-end;
  background-color: transparent;
  color: ${colors.primary};
`;

const StyledFee = styled(S.Fee)`
  margin-top: 16px;
`;

const PaymentInfo = styled.h2`
  font-size: 18px;

  ${mediaQueries.md} {
    font-size: 20px;
  }
`;

const PriceCell = styled(S.Cell)`
  padding-left: 20px;
`;

const PaypalWrapper = styled.div`
  width: 100%;
  max-width: 300px;

  button {
    width: 300px;

    ${mediaQueries.lg} {
      max-width: 300px;
    }
  }
`;

interface Fee {
  final: string;
  regular: string;
  discount: string;
}

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  fee: Fee;
  entries: Entry[];
  updateTable: () => void;
  couponDiscount: string | null;
}

export const PaymentModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  fee,
  entries,
  updateTable,
  couponDiscount,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const handler = () => {
    setIsFetching(true);
    apiClient
      .post(`${apiUrls.generateStrapiLink}`, {
        price: Number(fee.final),
        project_entry_ids: entries.map(x => x.id),
      })
      .then(({ data }) => (window.location.href = data.url))
      .catch(() => {
        setIsFetching(false);
        toast('Payment failed. Please try again', toastSettings);
      });
  };

  return (
    <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
      <ModalStyles />
      <CloseButton onClick={closeModal}>X</CloseButton>

      <StyledFee>
        Registration fee: <u>${fee.final}</u>
      </StyledFee>
      <S.FeeParagraph>
        Your discount: <u>-${fee.discount}</u>
      </S.FeeParagraph>
      {couponDiscount && (
        <S.FeeParagraph>
          Coupon discount: <u>-${couponDiscount}</u>
        </S.FeeParagraph>
      )}
      <S.FeeParagraph>
        Regular fee: <u>${fee.regular}</u>
      </S.FeeParagraph>

      <S.ModalTable>
        {entries.map(entry => (
          <React.Fragment key={entry.id}>
            <S.Cell>{getProjectName(entry.projectName)}</S.Cell>
            <PriceCell>${getFee(entry.awardCategory, entry.participantCategory)}</PriceCell>
          </React.Fragment>
        ))}
      </S.ModalTable>

      <PaymentInfo>Select the method of the payment</PaymentInfo>
      <PaypalWrapper>
        <PayPalButtons
          style={{ color: 'white', height: 40 }}
          createOrder={(data: unknown, actions: CreateOrderActions) =>
            actions.order.create({
              purchase_units: [{ amount: { value: fee.final } }],
              application_context: {
                shipping_preference: 'NO_SHIPPING',
              },
            })
          }
          onApprove={(data: unknown, actions: OnApproveActions) => {
            const projects = entries.map(entry =>
              payProject(entry.id, Number(fee.final) / entries.length),
            );

            return actions.order.capture().then(() =>
              Promise.all(projects)
                .then(closeModal)
                .then(updateTable)
                .then(() => toast('Payment successful', toastSettings))
                .catch(() =>
                  toast('Payment verification failed. Please contact us to verify it.', toastSettings),
                ),
            );
          }}
          onError={() => toast('Payment failed. Please try again', toastSettings)}
        />
        <CardButton onClick={handler} isFetching={isFetching} />
      </PaypalWrapper>
    </Modal>
  );
};
