import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, mediaQueries } from 'variables';
import { SingleCheckbox } from 'components/form/SingleCheckbox';
import { SecondaryButton } from 'components/common/Buttons';

export const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

export const Loader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 56px;
`;

export const Title = styled.h2`
  margin: 42px 0 52px;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

export const InfoParagraph = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 22.68px;
  margin: 102px 0 42px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  scrollbar-width: 10px;
  scrollbar-color: ${colors.background} ${colors.secondary};

  ::-webkit-scrollbar-track {
    background: ${colors.secondary};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.background};
    border: 3px solid ${colors.secondary};
  }
`;

export const Table = styled.div`
  width: 100%;
  min-width: 1200px;
  display: grid;
  grid-template-columns: 27% 37% 10% 12% 14%;
`;

export const DraftTable = styled(Table)`
  margin-top: initial;
  grid-template-columns: 3% 24% 37% 10% 12% 14%;
`;

export const ModalTable = styled(DraftTable)`
  margin: 30px 0;
  min-width: auto;
  grid-template-columns: 1fr 100px;
  border-top: 1px solid ${colors.secondary};
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.secondary};
  margin-bottom: 0;
  height: 56px;

  p {
    margin: 0;
  }
`;

export const ActionCell = styled(Cell)`
  justify-content: space-around;
`;

export const Action = styled.span`
  cursor: pointer;
`;

export const HiddenAction = styled.span`
  visibility: hidden;
`;

export const HeaderCell = styled(Cell)`
  font-family: Associate Mono Bold, sans-serif;
  margin-top: 20px;
`;

export const PaidTableInfo = styled.p`
  font-size: 16px;
  margin: 22px 0 72px;
`;

export const StyledCheckbox = styled(SingleCheckbox)`
  margin-bottom: 0;
`;

export const Fee = styled.h2`
  margin: 52px 0 16px;
`;

export const FeeParagraph = styled.p`
  margin: 0 0 12px;
`;

export const PayButton = styled(SecondaryButton)`
  margin: 27px 0 40px;
  width: 208px;
`;

export const NewEntryLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Associate Mono Bold, sans-serif;
  color: ${colors.primary};
  font-size: 18px;
  text-decoration: none;
  margin: 0 0 39px;

  span {
    font-family: Associate Mono, sans-serif;
    margin-left: 10px;
    font-size: 42px;
  }
`;

export const CouponInput = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid white;
  color: white;
  width: 100px;
`;
