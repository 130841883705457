import * as Yup from 'yup';

export const initialValues = {
  fullName: '',
  street: '',
  zipCode: '',
  city: '',
  country: '',
  bio: '',
};

export const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('This field cannot be empty'),
});

export const passwordInitialValues = {
  currentPassword: '',
  newPassword: '',
};

export const passwordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('This field cannot be empty'),
  newPassword: Yup.string().required('This field cannot be empty'),
});

export interface PasswordValues {
  currentPassword: string;
  newPassword: string;
}
