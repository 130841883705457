import React from 'react';
import styled from 'styled-components';

import { colors, mediaQueries } from 'variables';

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 48px 24px;

  ${mediaQueries.lg} {
    flex-direction: row;
  }
`;

const Link = styled.a`
  color: ${colors.primary};
`;

const EmailLink = styled(Link)`
  text-decoration: none;
`;

const Copyright = styled.span`
  ${mediaQueries.lg} {
    margin: 0 12px;
  }
`;

export const Footer: React.FC = () => (
  <Wrapper>
    <EmailLink href="mailto: hello@designeducates.com">hello@designeducates.com</EmailLink>
    <Copyright>© 2024 by Laka Foundation.</Copyright>

    <Link href="https://designeducates.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Privacy and Cookie Policy
    </Link>
  </Wrapper>
);
