import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { apiUrls, colors, mediaQueries } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { Input } from '../../components/form/Input';
import { SingleCheckbox } from '../../components/form/SingleCheckbox';
import { initialValues, RegisterRequestValues, validationSchema } from 'helpers/registerForm';
import { AuthContext, loginUser } from 'contexts/AuthContext';
import { ModalContext, openRegisterModal } from 'contexts/ModalContext';
import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const Title = styled.h2`
  margin: 42px;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 533px;
`;

const StyledButton = styled(PrimaryButton)`
  width: 213px;
  margin-top: 56px;
`;

const AdditionalInfo = styled.p`
  margin: 61px 0 108px;
`;

const Link = styled.a`
  color: ${colors.primary};
`;

const PrivacyLabel: JSX.Element = (
  <span>
    I agree with{' '}
    <Link href="https://designeducates.com/guidelines/" target="_blank" rel="noopener noreferrer">
      Guidelines & Privacy Policy
    </Link>
  </span>
);

export const Register: React.FC = () => {
  const [isFetching, setIsFetching] = React.useState(false);

  const history = useHistory();
  const { dispatch } = React.useContext(AuthContext);
  const { dispatch: dispatchModal } = React.useContext(ModalContext);

  const onSubmit = (values: RegisterRequestValues) => {
    setIsFetching(true);

    const registerBody = {
      fullName: values.name,
      email: values.email,
      password: values.password,
      agreed_to_privacy_policy: values.terms,
      agreed_to_dea_updates: values.newsletter,
    };

    const loginBody = {
      email: values.email,
      password: values.password,
    };

    apiClient
      .post(apiUrls.register, registerBody)
      .then(() => apiClient.post(apiUrls.login, loginBody))
      .then(res => dispatch(loginUser(res.data)))
      .then(() => dispatchModal(openRegisterModal(registerBody.fullName)))
      .then(() => history.push('/'))
      .catch(err => {
        err.response?.data?.errors?.forEach(({ message }: { message: string }) =>
          toast(message, toastSettings),
        ) || toast('Unable to register. Please try again later.', toastSettings);
        setIsFetching(false);
      });
  };

  return (
    <Wrapper>
      <Title>Sign up to Member Panel</Title>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange }) => (
          <StyledForm>
            <Input
              label="Full name"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
              touched={touched.name}
            />

            <Input
              label="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              touched={touched.email}
            />

            <Input
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              touched={touched.password}
            />

            <Input
              label="Confirm password"
              name="passwordConfirm"
              type="password"
              value={values.passwordConfirm}
              onChange={handleChange}
              error={errors.passwordConfirm}
              touched={touched.passwordConfirm}
            />

            <SingleCheckbox
              label={PrivacyLabel}
              name="terms"
              onChange={handleChange}
              value={values.terms}
              error={errors.terms}
              touched={touched.terms}
            />

            <SingleCheckbox
              label="I agree to receive DEA news and updates."
              name="newsletter"
              onChange={handleChange}
              value={values.newsletter}
              error={errors.newsletter}
              touched={touched.newsletter}
            />

            <StyledButton type="submit" disabled={isFetching}>
              Sign up
              {isFetching && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin" />
                </span>
              )}
            </StyledButton>

            <AdditionalInfo>
              Already have an account?{' '}
              <u style={{ cursor: 'pointer' }} onClick={() => history.push('/login')}>
                Login
              </u>
            </AdditionalInfo>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};
