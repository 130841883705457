import React from 'react';
import { Form, Formik } from 'formik';
import { Entry } from 'typings/entries';

import { getEntryPrice, getDiscountedPrice, formatPrice } from 'pages/awardsRegister/helpers';
import { StatusTag } from './StatusTag';
import * as S from './styles';
import { getFee, getProjectName, removeProject } from './helpers';
import { Categories } from './Categories';
import { PaymentModal } from './PaymentModal';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { useHistory } from 'react-router-dom';
import { routes } from 'variables';
import { CouponSection } from './CouponSection';

interface Props {
  entries: Entry[];
  displayHeader: boolean;
  updateTable: () => void;
}

export const DraftTable = ({
  entries: initialEntries,
  displayHeader,
  updateTable,
}: Props): JSX.Element => {
  const [fee, setFee] = React.useState({ final: '0.00', regular: '0.00', discount: '0.00' });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const history = useHistory();
  const [entries, setEntries] = React.useState(initialEntries);
  const [couponDiscount, setCouponDiscount] = React.useState<number | null>(null);

  const initialValues = Object.fromEntries(
    entries
      .filter(entry => entry.awardCategory.length && entry.participantCategory)
      .map(entry => [entry.id, true]),
  );

  if (entries.length === 0) {
    return <></>;
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={() => undefined}>
        {({ values, handleChange }) => {
          React.useEffect(() => {
            const selectedEntries = entries.filter(entry => values[entry.id]);
            const coupon = couponDiscount !== null ? 1 - couponDiscount / 100 : 1;

            const regularPrice = selectedEntries.reduce((acc, selectedEntry) => {
              const categoryNumbers = selectedEntry.awardCategory.length;
              const price = categoryNumbers * getEntryPrice(selectedEntry.participantCategory);

              return acc + price;
            }, 0);

            const numberOfEntries = selectedEntries.reduce(
              (acc, selectedEntry) => acc + selectedEntry.awardCategory.length,
              0,
            );

            const discountedPrice = getDiscountedPrice(regularPrice, numberOfEntries);

            const finalPrice = discountedPrice * coupon;

            setFee({
              final: formatPrice(finalPrice),
              regular: formatPrice(regularPrice),
              discount: formatPrice(regularPrice - discountedPrice),
            });
          }, [values, couponDiscount, entries]);

          return (
            <Form>
              <PaymentModal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                fee={fee}
                entries={entries.filter(entry => values[entry.id])}
                updateTable={updateTable}
                couponDiscount={
                  couponDiscount ? getCouponValue(fee.final, fee.discount, fee.regular) : null
                }
              />
              <S.TableWrapper>
                <S.DraftTable>
                  <S.HeaderCell />
                  <S.HeaderCell>{displayHeader ? ' Project name' : ''}</S.HeaderCell>
                  <S.HeaderCell>{displayHeader ? 'Categories' : ''}</S.HeaderCell>
                  <S.HeaderCell>{displayHeader ? 'Fee' : ''}</S.HeaderCell>
                  <S.HeaderCell>{displayHeader ? 'Status' : ''}</S.HeaderCell>
                  <S.HeaderCell />

                  {entries.map(entry => (
                    <React.Fragment key={entry.id}>
                      <S.Cell>
                        {entry.awardCategory.length !== 0 && entry.participantCategory && (
                          <S.StyledCheckbox
                            label=""
                            name={entry.id.toString()}
                            value={values[entry.id]}
                            onChange={handleChange}
                          />
                        )}
                      </S.Cell>
                      <S.Cell>{entry.projectName ? getProjectName(entry.projectName) : '-'}</S.Cell>
                      <S.Cell>
                        {entry.awardCategory.length ? (
                          <Categories categories={entry.awardCategory} />
                        ) : (
                          '-'
                        )}
                      </S.Cell>
                      <S.Cell>
                        {entry.awardCategory.length && entry.participantCategory
                          ? getFee(entry.awardCategory, entry.participantCategory)
                          : '-'}
                      </S.Cell>
                      <S.Cell>
                        <StatusTag status={entry.status} />
                      </S.Cell>
                      <S.ActionCell>
                        <S.Action
                          onClick={() => history.push(`${routes.awardsRegister}?id=${entry.id}`)}
                        >
                          Edit
                        </S.Action>
                        <S.Action
                          onClick={() => {
                            let shouldDelete = true;

                            setEntries(prevEntries => prevEntries.filter(({ id }) => id !== entry.id));
                            toast(
                              ({ closeToast }) => (
                                <p style={{ margin: 0 }}>
                                  Entry deleted.{' '}
                                  <u
                                    onClick={() => {
                                      shouldDelete = false;
                                      updateTable();
                                      closeToast && closeToast();
                                    }}
                                  >
                                    Undo
                                  </u>
                                </p>
                              ),
                              {
                                ...toastSettings,
                                closeOnClick: false,
                                onClose: () => {
                                  if (!shouldDelete) {
                                    return;
                                  }

                                  removeProject(entry.id);
                                },
                              },
                            );
                          }}
                        >
                          Delete
                        </S.Action>
                      </S.ActionCell>
                    </React.Fragment>
                  ))}
                </S.DraftTable>
              </S.TableWrapper>
            </Form>
          );
        }}
      </Formik>
      <S.Fee>
        Registration fee: <u>${fee.final}</u>
      </S.Fee>
      <S.FeeParagraph>
        Your discount: <u>-${fee.discount}</u>
      </S.FeeParagraph>
      <S.FeeParagraph>
        Regular fee: <u>${fee.regular}</u>
      </S.FeeParagraph>
      {couponDiscount && (
        <div style={{ marginTop: '24px' }}>
          <S.FeeParagraph>
            Coupon discount: <u>-${getCouponValue(fee.final, fee.discount, fee.regular)}</u>
          </S.FeeParagraph>
          <p style={{ marginTop: '-4px', cursor: 'pointer' }} onClick={() => setCouponDiscount(null)}>
            <u>Remove</u>
          </p>
        </div>
      )}

      <S.PayButton onClick={() => fee.final !== '0.00' && setIsModalOpen(true)}>Pay</S.PayButton>

      <CouponSection setDiscount={setCouponDiscount} />
    </>
  );
};

const getCouponValue = (final: string, discount: string, regular: string) =>
  formatPrice(Number(regular) - Number(discount) - Number(final));
