import * as Yup from 'yup';

export interface ForgotPasswordRequestValues {
  email: string;
}

export const initialValues = {
  email: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required field'),
});
