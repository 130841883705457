import * as Yup from 'yup';

export interface ChangePasswordRequestValues {
  password: string;
}

export const initialValues = {
  password: '',
  passwordConfirm: '',
};

export const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required field'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
