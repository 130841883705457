import * as Yup from "yup";

import { ParticipantCategory, ProjectCategory } from "typings/registerForm";

export const initialValues = {
  name: "",
  email: "",
  company: "",
  participantCategory: "",
  projectCategory: [] as string[],
  companyName: "",
  website: "",
  country: "",
  designTeam: "",
  projectName: "",
  client: "",
  location: "",
  description: "",
  image1: "",
  credits1: "",
  image2: "",
  credits2: "",
  image3: "",
  credits3: "",
  image4: "",
  credits4: "",
  image5: "",
  credits5: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required field"),
  email: Yup.string().email("Invalid email").required("Required field"),
  participantCategory: Yup.string().required("Required field"),
  projectCategory: Yup.array().min(1, "Please select at least one option"),
  companyName: Yup.string().required("Required field"),
  designTeam: Yup.string().required("Required field"),
  projectName: Yup.string().required("Required field"),
  description: Yup.string().required("Required field"),
  country: Yup.string().required("Required field"),
  image1: Yup.string().required("Featured Image is required"),
});

export const categoryOptions = [
  {
    label: "Individual student",
    value: ParticipantCategory.IndividualStudent,
  },
  {
    label: "Team of Students",
    value: ParticipantCategory.TeamOfStudents,
  },
  {
    label: "Individual professional",
    value: ParticipantCategory.IndividualProfessional,
  },
  {
    label: "Company < 10 employees",
    value: ParticipantCategory.CompanyGreaterThan10Employees,
  },
  {
    label: "Company 11 - 100 employees",
    value: ParticipantCategory.CompanyGreater11To100Employees,
  },
  {
    label: "Company 100+ employees",
    value: ParticipantCategory.CompanyGreaterThan100Employees,
  },
];

export const projectCategoryCheckboxes = [
  {
    label: "Architectural design",
    value: ProjectCategory.ArchitecturalDesign,
  },
  {
    label: "Product design",
    value: ProjectCategory.ProductDesign,
  },
  {
    label: "Universal design",
    value: ProjectCategory.UniversalDesign,
  },
  // {
  //   label: 'Responsive design',
  //   value: ProjectCategory.ResponsiveDesign,
  // },
];

export const formatPrice = (price: number): string =>
  (Math.round(price * 100) / 100).toFixed(2);

export const getEntryPrice = (category: string): number => {
  switch (category) {
    case ParticipantCategory.IndividualStudent:
      return 50;
    case ParticipantCategory.TeamOfStudents:
      return 50;
    case ParticipantCategory.IndividualProfessional:
      return 175;
    case ParticipantCategory.CompanyGreaterThan10Employees:
      return 175;
    case ParticipantCategory.CompanyGreater11To100Employees:
      return 350;
    default:
      return 600;
  }
};

export const getDiscountedPrice = (price: number, entries: number): number => {
  switch (entries) {
    case 1:
      return price;
    case 2:
      return price * 0.85;
    case 3:
      return price * 0.8;
    case 4:
      return price * 0.75;
    case 5:
      return price * 0.65;
    default:
      return price * 0.5;
  }
};

export const editInitialValues = {
  awardCategory: [] as string[],
  client: "",
  company: "",
  companyName: "",
  country: "",
  description: "",
  designTeam: "",
  email: "",
  fullName: "",
  location: "",
  participantCategory: "",
  projectName: "",
  status: "",
  website: "",
  image1: "",
  credits1: "",
  image2: "",
  credits2: "",
  image3: "",
  credits3: "",
  image4: "",
  credits4: "",
  image5: "",
  credits5: "",
};

export const editValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Required field"),
  email: Yup.string().email("Invalid email").required("Required field"),
  companyName: Yup.string().required("Required field"),
  designTeam: Yup.string().required("Required field"),
  projectName: Yup.string().required("Required field"),
  description: Yup.string().required("Required field"),
  awardCategory: Yup.array().min(1, "Please select at least one option"),
  participantCategory: Yup.string().required("Required field"),
  country: Yup.string().required("Required field"),
  image1: Yup.string().required("Featured Image is required"),
});

export const mapEntryToForm = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValues: (x: any) => void
): void => {
  const initialValues = {
    name: entry.fullName || "",
    email: entry.email || "",
    company: entry.company || "",
    participantCategory: entry.participantCategory || "",
    projectCategory: entry.awardCategory || "",
    companyName: entry.companyName || "",
    website: entry.website || "",
    country: entry.country || "",
    designTeam: entry.designTeam || "",
    projectName: entry.projectName || "",
    client: entry.client || "",
    location: entry.location || "",
    description: entry.description || "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",

    ...(entry.images[0] && {
      image1: entry.images[0].image,
      credits1: entry.images[0].credits,
    }),
    ...(entry.images[1] && {
      image2: entry.images[1].image,
      credits2: entry.images[1].credits,
    }),
    ...(entry.images[2] && {
      image3: entry.images[2].image,
      credits3: entry.images[2].credits,
    }),
    ...(entry.images[3] && {
      image4: entry.images[3].image,
      credits4: entry.images[3].credits,
    }),
    ...(entry.images[4] && {
      image5: entry.images[4].image,
      credits5: entry.images[4].credits,
    }),
  };

  setValues(initialValues);
};

export const mapEntryToSubmittedForm = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValues: (x: any) => void
): void => {
  const { images, ...values } = entry;

  const initialValues = {
    ...editInitialValues,
    ...values,
    ...(images[0] && { image1: images[0].image, credits1: images[0].credits }),
    ...(images[1] && { image2: images[1].image, credits2: images[1].credits }),
    ...(images[2] && { image3: images[2].image, credits3: images[2].credits }),
    ...(images[3] && { image4: images[3].image, credits4: images[3].credits }),
    ...(images[4] && { image5: images[4].image, credits5: images[4].credits }),
  };

  setValues(initialValues);
};
