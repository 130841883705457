import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { apiClient } from 'helpers/APIClient';
import { apiUrls, colors, mediaBase, mediaQueries } from 'variables';

const Label = styled.label`
  margin: 16px 0 12px;
  :first-of-type {
    margin-top: 0;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  min-height: 60px;
  margin-bottom: 32px;
  border: 2px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  span {
    font-size: 32px;
  }

  ${mediaQueries.lg} {
    max-width: 640px;
  }
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const UploaderParagraph = styled.p`
  margin: 0 0 6px;
`;

const UploaderSubParagraph = styled(UploaderParagraph)`
  color: ${colors.secondary};
`;

const ErrorMessage = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

const EmptyWrapper = styled.div`
  min-height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RemoveButton = styled.u`
  margin-top: -26px;
  margin-left: auto;
`;

const EmptyUploader = ({ error }: { error?: string }) => (
  <EmptyWrapper>
    <UploaderParagraph>Drag image here</UploaderParagraph>
    <UploaderSubParagraph>jpg / png</UploaderSubParagraph>
    <UploaderSubParagraph>min. 1000px</UploaderSubParagraph>
    <UploaderSubParagraph>max. 4mb</UploaderSubParagraph>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </EmptyWrapper>
);

interface Props {
  label: string;
  name: string;
  error?: string;
  setFieldValue: (field: string, value: unknown) => void;
  setFieldError: (field: string, value: string | undefined) => void;
  value: string;
}

export const ImageUpload = ({
  label,
  name,
  setFieldValue,
  error,
  setFieldError,
  value,
}: Props): JSX.Element => {
  const [isFetching, setIsFetching] = React.useState(false);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
    maxSize: 4 * 1024 * 1024,
  });

  React.useEffect(() => {
    if (fileRejections.length < 1) {
      return;
    }

    const error = fileRejections[0].errors[0];

    if (error.code === 'file-too-large') {
      return setFieldError(name, 'File is too large');
    }

    if (error.code === 'file-invalid-type') {
      return setFieldError(name, 'Invalid file type');
    }

    setFieldError(name, error.message);
  }, [fileRejections]);

  React.useEffect(() => {
    if (acceptedFiles.length === 0) {
      return;
    }

    setIsFetching(true);

    const formData = new FormData();
    formData.append('image', acceptedFiles[0]);

    apiClient
      .post(apiUrls.uploadProjectImage, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => setFieldValue(name, data.image))
      .catch(err => {
        setFieldValue(name, '');
        err.response.data.errors.forEach(({ message }: { message: string }) =>
          setFieldError(name, message),
        );
      })
      .finally(() => setIsFetching(false));
  }, [acceptedFiles]);

  return (
    <>
      <Label>{label}</Label>
      <InputWrapper {...getRootProps()}>
        {isFetching ? (
          <span>
            <i className="fa fa-circle-o-notch fa-spin" />
          </span>
        ) : (
          <>
            <input {...getInputProps()} name={name} />
            {value !== '' ? (
              <ImagePreview src={`${mediaBase}${value}`} />
            ) : (
              <EmptyUploader error={error} />
            )}
          </>
        )}
      </InputWrapper>
      {value !== '' && <RemoveButton onClick={() => setFieldValue(name, '')}>Remove image</RemoveButton>}
    </>
  );
};
